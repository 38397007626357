import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Recommendation} from '@/_models';
import { IssueService } from '@/_services';

@Component({
  selector: 'app-planning-side',
  templateUrl: 'planning-side.component.html',
  styleUrls: ['planning-side.component.less']
})

export class PlanningSideComponent implements OnInit {

  @Input() issues;
  @Input() assignedIssues;
  @Input() selectedIssue;
  @Output() selectedIssueOut = new EventEmitter();
  @Output() activeTabOut = new EventEmitter();

  activeTab = 1;

  constructor() { }

  ngOnInit() { }

  selectIssue(issue) {
    this.selectedIssue = issue;
    this.selectedIssueOut.emit(this.selectedIssue);
  }

  setTab(id) {
    this.activeTab = id;
    this.activeTabOut.emit(id);
  }

}

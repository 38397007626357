<div class="issue-item">
  <div class="item-header">
    <div class="date">{{issue.date}}</div>
    <div class="title">{{issue.title}}</div>
  </div>
  <div class="responsible">
    Ответственный: <span>{{issue.responsible}}</span>
  </div>
  <div class="brigada">
    Бригада: <span>{{issue.crue ? issue.crue.join(', ') : issue.brigada}}</span>
  </div>
  <div class="percent">
    <div class="inner" [ngStyle]="{'width': issue.prioritet+'%'}"></div>
  </div>
</div>

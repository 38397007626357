import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-assign-popup',
  templateUrl: './assign-popup.component.html',
  styleUrls: ['./assign-popup.component.less']
})
export class AssignPopupComponent implements OnInit {

  @Input() selectedIssue;
  @Input() openAssignPopup;
  @Input() overlay;
  @Output() assignIssueOut = new EventEmitter();
  @Output() closePopupOut = new EventEmitter();

  date = new FormControl();

  crue = new FormControl();
  crueList: object[] = [
    { name: 'Бригада №1', distance: '2,1 км' },
    { name: 'Бригада №2', distance: '3,4 км' },
    { name: 'Бригада №3', distance: '4,9 км' }
    ];

  vehicle = new FormControl();
  vehicleList: string[] = ['Кран', 'Бульдозер', 'Погрузчик'];

  responsible = new FormControl();
  responsibleList: string[] = ['Иванов И.И.', 'Петров П.П.', 'Сидоров С.С.'];



  constructor() { }

  ngOnInit() {
  }

  closePopup() {
    this.closePopupOut.emit(true);
  }

  assignIssue() {
    this.assignIssueOut.emit({
      date: this.date.value.format('DD.MM.YY'),
      crue: this.crue.value,
      vehicle: this.vehicle.value,
      responsible: this.responsible.value
    });
    this.closePopupOut.emit(true);
  }

  log($event) {
    console.log($event);
  }
}

<div class="side-content">

  <button [routerLink]="['/issues']" routerLinkActive="active">
    <svg id="issues-icon" class="icon icon-issues" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.3333 5.33301H6.66667C5.19391 5.33301 4 6.52692 4 7.99967V26.6663C4 28.1391 5.19391 29.333 6.66667 29.333H25.3333C26.8061 29.333 28 28.1391 28 26.6663V7.99967C28 6.52692 26.8061 5.33301 25.3333 5.33301Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M21.3333 2.66699V8.00033" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.6667 2.66699V8.00033" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4 13.333H28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </button>

  <button [routerLink]="['/planning']" routerLinkActive="active">
    <svg id="planning-icon" class="icon icon-planning"  width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="fillpath" d="M25.3333 2.66667C26.0687 2.66667 26.6667 3.26467 26.6667 4V18.6667C26.6667 19.402 26.0687 20 25.3333 20H14.6667H13.8123L13.117 20.4967L8 24.1513V22.6667V20H5.33333H4C3.26467 20 2.66667 19.402 2.66667 18.6667V4C2.66667 3.26467 3.26467 2.66667 4 2.66667H25.3333ZM25.3333 0H4C1.791 0 0 1.791 0 4V18.6667C0 20.8757 1.791 22.6667 4 22.6667H5.33333V29.3333L14.6667 22.6667H25.3333C27.5423 22.6667 29.3333 20.8757 29.3333 18.6667V4C29.3333 1.791 27.5423 0 25.3333 0Z"/>
      <path d="M7 8H22" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7 14H15" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </button>

  <button [routerLink]="['/overview']" routerLinkActive="active">
    <svg id="overview-icon" class="icon icon-overview" width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="5" width="6" height="23" rx="2" stroke-width="2"/>
      <path d="M13 7C13 5.89543 13.8954 5 15 5H17C18.1046 5 19 5.89543 19 7V22C19 23.1046 18.1046 24 17 24H15C13.8954 24 13 23.1046 13 22V7Z" stroke-width="2"/>
      <rect x="25" y="5" width="6" height="15" rx="2" stroke-width="2"/>
      <path d="M1 1H31" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </button>

</div>

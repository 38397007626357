<div class="popup-overlay" (click)="closePopup()" *ngIf="openAssignPopup"></div>
<div class="assign-issue-popup" [hidden]="!openAssignPopup">
  <div class="close" (click)="closePopup()">
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L17 17M17 1L1 17" stroke="#01628A" stroke-width="2"/>
    </svg>
  </div>
  <div class="assign-popup-content">
    <div class="popup-title">{{selectedIssue?selectedIssue.title:''}}</div>
  <div class="mat-datepicker-input-wrapper">
    <mat-form-field appearance="legacy">
      <label class="label-custom">Выберите дату</label>
      <input matInput [formControl]="date" [matDatepicker]="picker" (dateChange)="log($event)">
      <mat-datepicker-toggle matSuffix [for]="picker">
        <mat-icon matDatepickerToggleIcon>
          <svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 1L10.8284 5.17157C9.26633 6.73367 6.73367 6.73367 5.17157 5.17157L1 1" stroke="#C4C4C4"/>
          </svg>
        </mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
    <mat-form-field>
      <label class="label-custom">Бригада</label>
      <mat-select [formControl]="crue" multiple disableOptionCentering panelClass="mat-select-panel-custom">
        <mat-select-trigger>
          {{crue.value ? crue.value[0] : ''}}
          <span *ngIf="crue.value?.length > 1" class="example-additional-selection">
        (+{{crue.value.length - 1}} {{crue.value?.length === 2 ? 'ещё' : 'ещё'}})
      </span>
        </mat-select-trigger>
        <mat-option *ngFor="let crue of crueList" [value]="crue.name">
          <span style="display: flex; justify-content: space-between">
            <span>{{crue.name}}</span><span>..............................</span><span>~{{crue.distance}}</span>
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <label class="label-custom">Ответственный</label>
      <mat-select [formControl]="responsible">
        <mat-select-trigger>
          {{responsible.value ? responsible.value : ''}}
        </mat-select-trigger>
        <mat-option *ngFor="let responsible of responsibleList" [value]="responsible">{{responsible}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="popup_bottom">
    <div class="assign-issue">
      <button (click)="assignIssue()">Назначить задачу</button>
    </div>
  </div>
</div>

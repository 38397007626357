import { Component, OnInit, Injectable } from '@angular/core';
import { IssueService } from '@/_services';

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.less']
})

export class PlanningComponent implements OnInit {

  issues = [];

  selectedIssue;
  issuesAssigned = false;

  constructor(
    private issueService: IssueService
  ) { }

  ngOnInit() {
    this.issues = this.issueService.getUnassigned();
  }

  selectIssue(selectedIssue) {
    this.selectedIssue = selectedIssue;
  }

  assignIssue(data) {
    this.issueService.assign(data);
    this.issues = this.issueService.getUnassigned();
  }

  toggleMapIssues(id) {
    this.selectedIssue = undefined;
    if (id === 1) {
      this.issuesAssigned = false;
      this.issues = this.issueService.getUnassigned();
    } else {
      this.issuesAssigned = true;
      this.issues = this.issueService.getAssigned();
    }

  }
}

<div class="overlay" (click)="closePopup()"></div>
<div class="issue-popup">
  <div class="info">
    <div class="popup-title">Состояние дорожной инфраструктуры:</div>
    <hr />
    <div class="cb"></div>
    <div class="defects-amount">Обнаружено нарушений : <span [ngClass]="(defectsAmount>0)? 'found':''">{{defectsAmount}}</span></div>
    <div class="defects-list">
      <div class="defect" *ngFor="let defect of selectedIssue.defects">
        <div>{{defect.label}}:</div>
        <div [ngClass]="defect.value? 'found':''">{{defect.value? 'обнаружено': 'не обнаружено'}}</div>
      </div>
    </div>
    <div class="sources">
      <hr />
      <div class="cb"></div>
      <div class="sources-title">Источники информации:</div>
      <ul>
        <li class="source" *ngFor="let source of selectedIssue.sources" [innerHTML]="source.html | html"></li>
      </ul>
    </div>
  </div>
  <div class="slider">
    <!--<svg class="arrow_up" width="40" height="10" viewBox="0 0 40 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 0L40 9.68229H0L20 0Z"/>
    </svg>-->
    <div class="images">
      <div *ngFor="let image of selectedIssue.images"><img src="/assets/issues-images/{{image}}" /></div>
    </div>
    <!--<svg class="arrow_down" width="40" height="10" viewBox="0 0 40 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 10L40 0.317709H0L20 10Z"/>
    </svg>-->
  </div>







</div>

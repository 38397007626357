import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize } from 'rxjs/operators';

import { User, Issue, Recommendation } from '../_models';

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const users: User[] = [
      { id: 1, username: 'admin', password: 'password', firstName: 'Test', lastName: 'User' }
    ];

    const issues: Issue[] = [
      {
        id: 1, title: 'MAN TGL', number: 'M 451 КВ 76', stars: 2, closed: true,
        tasks: [
        'Замена передних и задних тормозных дисков',
        'Замена передних и задних тормозных колодок',
        'Проверка подшипников ступицы правой оси'
        ]
      },
      {
        id: 2, title: 'Камаз', number: 'K 451 КВ 98', stars: 4, closed: true,
        tasks: [
          'Замена передних и задних тормозных дисков',
          'Замена передних и задних тормозных колодок',
          'Проверка подшипников ступицы правой оси'
        ]
      },
      {
        id: 3, title: 'Вольво', number: 'E 759 КВ 199', stars: 1, closed: true,
        tasks: [
          'Замена передних и задних тормозных дисков',
          'Замена передних и задних тормозных колодок',
          'Проверка подшипников ступицы правой оси'
        ]
      }
    ];

    const recommendations: Recommendation[] = [
      { id: 1, description: 'Поменять знак ограничения скорости с 60 до 40 км./ч.' },
      { id: 2, description: 'Поменять знак ограничения скорости с 20 до 40 км./ч.' },
      { id: 3, description: 'Поменять знак ограничения скорости с 60 до 20 км./ч.' }
    ];

    // const authHeader = request.headers.get('Authorization');
    const isLoggedIn = true; // authHeader && authHeader.startsWith('Bearer fake-jwt-token');

    // wrap in delayed observable to simulate server api call
    return of(null).pipe(mergeMap(() => {

      // authenticate - public
      if (request.url.endsWith('/auth') && request.method === 'POST') {
        const user = users.find(x => x.username === request.body.username && x.password === request.body.password);
        if (!user) {
          return error('Username or password is incorrect');
        }
        return ok({
          id: user.id,
          username: user.username,
          firstName: user.firstName,
          lastName: user.lastName,
          token: `fake-jwt-token`
        });
      }

      // get all users
      if (request.url.endsWith('/users') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }
        return ok(users);
      }

      // get all users
      if (request.url.endsWith('/issues') && request.method === 'GET') {
        // if (!isLoggedIn) return unauthorised();
        return ok(issues);
      }

      // get all recommendations
      if (request.url.endsWith('/recommendations') && request.method === 'GET') {
        // if (!isLoggedIn) return unauthorised();
        return ok(recommendations);
      }

      // pass through any requests not handled above
      return next.handle(request);
    }))
    // call materialize and dematerialize to ensure delay even if an
    // error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
      .pipe(materialize())
      .pipe(delay(500))
      .pipe(dematerialize());

    // private helper functions

    function ok(body) {
      return of(new HttpResponse({ status: 200, body }));
    }

    function unauthorised() {
      return throwError({ status: 401, error: { message: 'Unauthorised' } });
    }

    function error(message) {
      return throwError({ status: 400, error: { message } });
    }
  }
}

export let fakeBackendProvider = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: FakeBackendInterceptor,
  multi: true
};

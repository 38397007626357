import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-issues-map',
  templateUrl: './issues-map.component.html',
  styleUrls: ['./issues-map.component.less']
})
export class IssuesMapComponent implements OnInit {

  selectDate = false;

  selectedIssue;

  issues = [
    {
      id: 1,
      date: '08.03.2018&nbsp;&nbsp;&nbsp;15:30 - 16:00',
      roadSection: 1,
      defects: [
        {label: 'Разрушение дорожной разметки', value: false},
        {label: 'Загрязнение дорожных знаков', value: false},
        {label: 'Неисправность светофоров', value: false},
        {label: 'Посторонние предметы на дороге', value: false},
        {label: 'Повреждения дорожного полотна', value: false}
      ],
      sources: [
        {html: 'Данные от 16 автомобилей: 12 легковых, 4 грузовых'},
        {html: 'Системы ФВФ, установленной на опоре 1604, 16,07'},
        {html: 'Система мониторинга КДМ'}
      ],
      images: [
        'issue-1-img-1.png', 'issue-1-img-2.png', 'issue-1-img-3.png', 'issue-1-img-4.png'
      ]
    },
    {
      id: 2,
      date: '13.07.2018&nbsp;&nbsp;&nbsp;15:30 - 16:00',
      roadSection: 1,
      defects: [
        {label: 'Разрушение дорожной разметки', value: false},
        {label: 'Загрязнение дорожных знаков', value: false},
        {label: 'Неисправность светофоров', value: true},
        {label: 'Посторонние предметы на дороге', value: false},
        {label: 'Повреждения дорожного полотна', value: false}
      ],
      sources: [
        {html: 'Данные от 21 автомобилей: 18 легковых, 3 грузовых'},
        {html: 'Сенсоры, установленных на опоре 1756, 1748'},
        {html: 'Система мониторинга КДМ'}
      ],
      images: [
        'issue-2-img-1.png', 'issue-2-img-2.png', 'issue-2-img-3.png', 'issue-2-img-4.png'
      ]
    },
    {
      id: 3,
      date: '13.03.2019&nbsp;&nbsp;&nbsp;15:30 - 16:00',
      roadSection: 1,
      defects: [
        {label: 'Разрушение дорожной разметки', value: true},
        {label: 'Загрязнение дорожных знаков', value: false},
        {label: 'Неисправность светофоров', value: false},
        {label: 'Посторонние предметы на дороге', value: false},
        {label: 'Повреждения дорожного полотна', value: false}
      ],
      sources: [
        {html: 'Данные от 16 автомобилей: 12 легковых, 4 грузовых'},
        {html: 'Системы ФВФ, установленной на опоре 2101, 2151'},
        {html: 'Сенсоры, установленных на опоре 0031, 0036, 0037'}
      ],
      images: [
        'issue-3-img-1.png', 'issue-3-img-2.png', 'issue-3-img-3.png', 'issue-3-img-4.png'
      ]
    }
  ];

  constructor() { }

  ngOnInit() { }

  toggleDateSelector() {
    this.selectDate = !this.selectDate;
  }

}

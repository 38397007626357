import { Component, OnInit, Input } from '@angular/core';
import {Import} from "@angular/compiler-cli/src/ngtsc/host";

@Component({
  selector: 'app-issue-item',
  templateUrl: './issue-item.component.html',
  styleUrls: ['./issue-item.component.less']
})
export class IssueItemComponent implements OnInit {

  @Input() issue;

  constructor() { }

  ngOnInit() {
  }

}

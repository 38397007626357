import { Component, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {IssueService} from '@/_services';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.less']
})
export class OverviewComponent implements OnInit {

  issues = [];

  plannedIssues = this.issues.filter(issue => issue.status === 'planned');
  inProgressIssues = this.issues.filter(issue => issue.status === 'in progress');
  finishedIssues = this.issues.filter(issue => issue.status === 'finished');

  constructor(
    private issueService: IssueService
  ) {
    this.issues = this.issueService.getAssigned();
  }

  ngOnInit() {

    this.plannedIssues = this.issues.filter(issue => issue.status === 'planned');
    this.inProgressIssues = this.issues.filter(issue => issue.status === 'in progress');
    this.finishedIssues = this.issues.filter(issue => issue.status === 'finished');

  }

  drop(event: CdkDragDrop<string[]>) {
    this.plannedIssues.forEach(issue => issue.status = 'planned');
    this.inProgressIssues.forEach(issue => issue.status = 'in progress');
    this.finishedIssues.forEach(issue => issue.status = 'finished');

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.issueService.updateIssues(
      this.plannedIssues.map(issue => issue.id),
      this.inProgressIssues.map(issue => issue.id),
      this.finishedIssues.map(issue => issue.id)
    );
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { fakeBackendProvider } from './_helpers';
import { MomentModule } from 'ngx-moment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StartComponent } from './start/start.component';
import {HttpClientModule} from '@angular/common/http';
import {AsideComponent} from '@/shared/components/aside/aside.component';
import {HeaderComponent} from '@/shared/components/header/header.component';
import {ModalDialogComponent} from '@/shared/components/modal-dialog/modal-dialog.component';
import {PageNotFoundComponent} from '@/shared/components/page-not-found/page-not-found.component';
import { PlanningComponent } from './planning/planning.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { PlanningSideComponent } from './planning/planning-side/planning-side.component';
import { OverviewComponent } from './overview/overview.component';
import { MapComponent } from './planning/map/map.component';
import { SelectedPipe } from './_pipes/selected.pipe';
import { IssueItemComponent } from './overview/issue-item/issue-item.component';
import { IssuesPipe } from './_pipes/issues.pipe';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { AssignPopupComponent } from './planning/assign-popup/assign-popup.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MAT_DATE_LOCALE} from '@angular/material';
import { IssuesComponent } from './issues/issues.component';
import { IssuesMapComponent } from './issues/issues-map/issues-map.component';
import { IssuePopupComponent } from './issues/issue-popup/issue-popup.component';
import {SafeHtmlPipe} from '@/_pipes/safe-html.pipe';
// import { JwtInterceptor, ErrorInterceptor } from './_helpers';

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    AsideComponent,
    HeaderComponent,
    ModalDialogComponent,
    PageNotFoundComponent,
    PlanningComponent,
    AnalyticsComponent,
    PlanningSideComponent,
    OverviewComponent,
    MapComponent,
    SelectedPipe,
    IssueItemComponent,
    IssuesPipe,
    AssignPopupComponent,
    IssuesComponent,
    IssuesMapComponent,
    IssuePopupComponent,
    SafeHtmlPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatIconModule,
    MatMomentDateModule,
    MomentModule.forRoot({ relativeTimeThresholdOptions: { m: 59 } })
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'ru-RU'},
    // {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    // {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

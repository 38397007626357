/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./planning-side.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./planning-side.component";
var styles_PlanningSideComponent = [i0.styles];
var RenderType_PlanningSideComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PlanningSideComponent, data: {} });
export { RenderType_PlanningSideComponent as RenderType_PlanningSideComponent };
function View_PlanningSideComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectIssue(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "number"], ["style", "float: left"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["style", "clear: both"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "percent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "inner"]], null, null, null, null, null)), i1.ɵdid(10, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(11, { "width": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "item"; var currVal_1 = ((_co.selectedIssue === _v.context.$implicit) ? "active" : ""); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = _ck(_v, 11, 0, (_v.context.$implicit.prioritet + "%")); _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = (_v.context.index + 1); _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.$implicit.recommendation; _ck(_v, 6, 0, currVal_3); }); }
export function View_PlanningSideComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "tabs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "tab"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setTab(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "tab-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0421\u043E\u0431\u044B\u0442\u0438\u044F"])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "tab"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setTab(2) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "tab-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0412 \u0440\u0430\u0431\u043E\u0442\u0435"])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "recommendations"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "recommendations-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlanningSideComponent_1)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tab"; var currVal_1 = ((_co.activeTab === 1) ? "active" : ""); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "tab"; var currVal_3 = ((_co.activeTab === 2) ? "active" : ""); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_4 = _co.issues; _ck(_v, 12, 0, currVal_4); }, null); }
export function View_PlanningSideComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-planning-side", [], null, null, null, View_PlanningSideComponent_0, RenderType_PlanningSideComponent)), i1.ɵdid(1, 114688, null, 0, i3.PlanningSideComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PlanningSideComponentNgFactory = i1.ɵccf("app-planning-side", i3.PlanningSideComponent, View_PlanningSideComponent_Host_0, { issues: "issues", assignedIssues: "assignedIssues", selectedIssue: "selectedIssue" }, { selectedIssueOut: "selectedIssueOut", activeTabOut: "activeTabOut" }, []);
export { PlanningSideComponentNgFactory as PlanningSideComponentNgFactory };

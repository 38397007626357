import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.less']
})
export class MapComponent implements OnInit {

  @Input() issuesAssigned;
  @Input() issues;
  @Input() selectedIssue;
  @Output() selectedIssueOut = new EventEmitter();
  @Output() assignedIssueOut = new EventEmitter();

  overlay = true;
  openAssignPopup = false;

  constructor() { }

  ngOnInit() {
    console.log(this.issues);
  }

  assignIssue($event) {
    if (this.openAssignPopup === false) {

      this.openAssignPopup = true;
    }
    $event.stopPropagation();
  }

  selectIssue(issue) {
    if (this.selectedIssue === issue) {
      this.selectedIssue = undefined;
    } else {
      this.selectedIssue = issue;
    }
    this.selectedIssueOut.emit(this.selectedIssue);
  }

  assignSelectedIssue(data) {
    this.assignedIssueOut.emit({issue: Object.assign({}, this.selectedIssue), data});
    this.closeAll();
  }

  closeAll() {
    this.selectIssue(undefined);
  }
}

<div class="issues-map">
  <div class="date-time-selector">
    <div class="date" (click)="toggleDateSelector()">
      <span class="selected-issue" [innerHTML]="selectedIssue ? (selectedIssue.date | html): 'Выберите дату'"></span>
      <svg class="icon-calendar" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
          <path d="M14.25 2H3.75C2.92157 2 2.25 2.67157 2.25 3.5V14C2.25 14.8284 2.92157 15.5 3.75 15.5H14.25C15.0784 15.5 15.75 14.8284 15.75 14V3.5C15.75 2.67157 15.0784 2 14.25 2Z" stroke="#FF8946" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12 0.5V3.5" stroke="#FF8946" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M6 0.5V3.5" stroke="#FF8946" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M2.25 6.5H15.75" stroke="#FF8946" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="18" height="18" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
    <div class="variants" [hidden]="!selectDate">
      <div *ngFor="let issue of issues; index as i" (click)="selectedIssue=undefined; selectDate=false; selectedIssue=issue;" [innerHTML]="issue.date | html"></div>
    </div>
  </div>
  <app-issue-popup *ngIf="selectedIssue" [selectedIssue]="selectedIssue" [selectDate]="selectDate" (selectDateOut)="selectDate=false; selectedIssue=undefined;"></app-issue-popup>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./aside.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./aside.component";
var styles_AsideComponent = [i0.styles];
var RenderType_AsideComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AsideComponent, data: {} });
export { RenderType_AsideComponent as RenderType_AsideComponent };
export function View_AsideComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 32, "div", [["class", "side-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "button", [["routerLinkActive", "active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, [[1, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 1), i1.ɵdid(4, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(7, 0, null, null, 4, ":svg:svg", [["class", "icon icon-issues"], ["fill", "none"], ["height", "32"], ["id", "issues-icon"], ["viewBox", "0 0 32 32"], ["width", "32"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, ":svg:path", [["d", "M25.3333 5.33301H6.66667C5.19391 5.33301 4 6.52692 4 7.99967V26.6663C4 28.1391 5.19391 29.333 6.66667 29.333H25.3333C26.8061 29.333 28 28.1391 28 26.6663V7.99967C28 6.52692 26.8061 5.33301 25.3333 5.33301Z"], ["stroke-linecap", "round"], ["stroke-linejoin", "round"], ["stroke-width", "2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, ":svg:path", [["d", "M21.3333 2.66699V8.00033"], ["stroke-linecap", "round"], ["stroke-linejoin", "round"], ["stroke-width", "2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, ":svg:path", [["d", "M10.6667 2.66699V8.00033"], ["stroke-linecap", "round"], ["stroke-linejoin", "round"], ["stroke-width", "2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, ":svg:path", [["d", "M4 13.333H28"], ["stroke-linecap", "round"], ["stroke-linejoin", "round"], ["stroke-width", "2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 9, "button", [["routerLinkActive", "active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, [[3, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(14, 1), i1.ɵdid(15, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(18, 0, null, null, 3, ":svg:svg", [["class", "icon icon-planning"], ["fill", "none"], ["height", "30"], ["id", "planning-icon"], ["viewBox", "0 0 30 30"], ["width", "30"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, ":svg:path", [["class", "fillpath"], ["d", "M25.3333 2.66667C26.0687 2.66667 26.6667 3.26467 26.6667 4V18.6667C26.6667 19.402 26.0687 20 25.3333 20H14.6667H13.8123L13.117 20.4967L8 24.1513V22.6667V20H5.33333H4C3.26467 20 2.66667 19.402 2.66667 18.6667V4C2.66667 3.26467 3.26467 2.66667 4 2.66667H25.3333ZM25.3333 0H4C1.791 0 0 1.791 0 4V18.6667C0 20.8757 1.791 22.6667 4 22.6667H5.33333V29.3333L14.6667 22.6667H25.3333C27.5423 22.6667 29.3333 20.8757 29.3333 18.6667V4C29.3333 1.791 27.5423 0 25.3333 0Z"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, ":svg:path", [["d", "M7 8H22"], ["stroke-linecap", "round"], ["stroke-linejoin", "round"], ["stroke-width", "2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, ":svg:path", [["d", "M7 14H15"], ["stroke-linecap", "round"], ["stroke-linejoin", "round"], ["stroke-width", "2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 10, "button", [["routerLinkActive", "active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(23, 16384, [[5, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(24, 1), i1.ɵdid(25, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 5, { links: 1 }), i1.ɵqud(603979776, 6, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(28, 0, null, null, 4, ":svg:svg", [["class", "icon icon-overview"], ["fill", "none"], ["height", "29"], ["id", "overview-icon"], ["viewBox", "0 0 32 29"], ["width", "32"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 0, ":svg:rect", [["height", "23"], ["rx", "2"], ["stroke-width", "2"], ["width", "6"], ["x", "1"], ["y", "5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 0, ":svg:path", [["d", "M13 7C13 5.89543 13.8954 5 15 5H17C18.1046 5 19 5.89543 19 7V22C19 23.1046 18.1046 24 17 24H15C13.8954 24 13 23.1046 13 22V7Z"], ["stroke-width", "2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 0, ":svg:rect", [["height", "15"], ["rx", "2"], ["stroke-width", "2"], ["width", "6"], ["x", "25"], ["y", "5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 0, ":svg:path", [["d", "M1 1H31"], ["stroke-linecap", "round"], ["stroke-linejoin", "round"], ["stroke-width", "2"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, "/issues"); _ck(_v, 2, 0, currVal_0); var currVal_1 = "active"; _ck(_v, 4, 0, currVal_1); var currVal_2 = _ck(_v, 14, 0, "/planning"); _ck(_v, 13, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 15, 0, currVal_3); var currVal_4 = _ck(_v, 24, 0, "/overview"); _ck(_v, 23, 0, currVal_4); var currVal_5 = "active"; _ck(_v, 25, 0, currVal_5); }, null); }
export function View_AsideComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-aside", [], null, null, null, View_AsideComponent_0, RenderType_AsideComponent)), i1.ɵdid(1, 114688, null, 0, i3.AsideComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AsideComponentNgFactory = i1.ɵccf("app-aside", i3.AsideComponent, View_AsideComponent_Host_0, {}, {}, []);
export { AsideComponentNgFactory as AsideComponentNgFactory };

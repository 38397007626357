<div class="tabs">
  <div class="tab" [ngClass]="activeTab===1? 'active':''" (click)="setTab(1)">
    <div class="tab-label">События</div>
  </div>
  <div class="tab" [ngClass]="activeTab===2? 'active':''" (click)="setTab(2)">
    <div class="tab-label">В работе</div>
  </div>
</div>

<div class="recommendations">

  <div class="recommendations-list">
    <div class="item"
         *ngFor="let issue of issues; index as i"
         (click)="selectIssue(issue)"
         [ngClass]="(selectedIssue === issue)? 'active':''"
    >
      <div>
        <div class="number" style="float: left">{{i+1}}</div>
        <div>
          {{issue.recommendation}}
        </div>

        <div style="clear: both"></div>
      </div>
      <div class="percent">
        <div class="inner" [ngStyle]="{'width':issue.prioritet +'%'}"></div>
      </div>
    </div>
  </div>


</div>

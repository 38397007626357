import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {PlanningComponent} from '@/planning/planning.component';
import {AnalyticsComponent} from '@/analytics/analytics.component';
import {OverviewComponent} from '@/overview/overview.component';
import {IssuesComponent} from '@/issues/issues.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'issues',
    pathMatch: 'full'

  },
  {
    path: 'issues',
    component: IssuesComponent
  },
  {
    path: 'planning',
    component: PlanningComponent
  },
  {
    path: 'analytics',
    component: AnalyticsComponent
  }
  ,
  {
    path: 'overview',
    component: OverviewComponent
  },
  {
    path: '',
    redirectTo: 'planning',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div class="overview-page">
  <div class="page-header">
    <div class="coll coll-1">
      <div class="badge">
        <div class="badge-content">Запланировано</div>
      </div>
    </div>
    <div class="coll coll-2">
      <div class="badge">
        <div class="badge-content">В работе</div>
      </div>
    </div>
    <div class="coll coll-3">
      <div class="badge">
        <div class="badge-content">Завершено</div>
      </div>
    </div>
  </div>
  <div class="page-content">
    <div class="coll coll-1"
         cdkDropList
         #plannedList="cdkDropList"
         [cdkDropListData]="plannedIssues"
         [cdkDropListConnectedTo]="[inProgressList]"
         (cdkDropListDropped)="drop($event)"
    >
      <app-issue-item cdkDrag *ngFor="let issue of plannedIssues" [issue]="issue"></app-issue-item>
    </div>
    <div class="coll coll-2"
         cdkDropList
         #inProgressList="cdkDropList"
         [cdkDropListData]="inProgressIssues"
         [cdkDropListConnectedTo]="[finishedList]"
         (cdkDropListDropped)="drop($event)"
    >
      <app-issue-item cdkDrag *ngFor="let issue of inProgressIssues" [issue]="issue"></app-issue-item>
    </div>
    <div class="coll coll-3"
         cdkDropList
         #finishedList="cdkDropList"
         [cdkDropListData]="finishedIssues"
         [cdkDropListConnectedTo]="[plannedList]"
         (cdkDropListDropped)="drop($event)"
    >
      <app-issue-item cdkDrag *ngFor="let issue of finishedIssues" [issue]="issue"></app-issue-item>
    </div>
  </div>
</div>

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as $ from 'node_modules/jquery';
import 'node_modules/slick-carousel/slick/slick.min';

@Component({
  selector: 'app-issue-popup',
  templateUrl: './issue-popup.component.html',
  styleUrls: ['./issue-popup.component.less']
})
export class IssuePopupComponent implements OnInit {

  @Input() selectDate;
  @Input() selectedIssue;

  @Output() selectDateOut = new EventEmitter();
  @Output() reInitSliderOut = new EventEmitter();

  defectsAmount = 0;
  constructor() { }

  ngOnInit() {
    this.defectsAmount = this.selectedIssue.defects.filter(defect => defect.value).length;
    this.reInitSlider();

  }

  closePopup() {
    this.selectDateOut.emit(false);
  }

  reInitSlider() {

    setTimeout(() => {
      $('.images').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        infinite: false,
        speed: 500,
        vertical: true,
        verticalSwiping: true,
        prevArrow: 'none',
        nextArrow: 'none'
      });
    }, 0);
  }
}

import * as i0 from "@angular/core";
var IssueService = /** @class */ (function () {
    function IssueService() {
        this.issues = [
            {
                id: 1,
                title: 'Ремонт дорожного покрытия на Фермском шоссе',
                date: '12.04.19',
                responsible: 'Петров В.С.',
                brigada: '№1',
                prioritet: 90,
                status: 'planned',
                cords: { x: 400, y: 500 },
                reason: 'Повторные ДТП на этом участке дороги',
                recommendation: 'Очистить дорожные знаки и разметку, провести осмотр дорожного покрытия',
                neededResources: [
                    { viewName: 'информация из ГИБДД', amount: '' }
                ],
                img: 'issue-img.png',
                sources: [
                    'Данные от 10 автомобилей, проехавших за 16 минут', 'информация из ГИБДД'
                ]
            },
            {
                id: 2,
                title: 'Ремонт дорожного покрытия на Фермском шоссе',
                date: '12.04.19',
                responsible: 'Петров В.С.',
                brigada: '№1',
                prioritet: 90,
                status: 'planned',
                cords: { x: 358, y: 550 },
                reason: 'Проблема с дорожным покрытием',
                recommendation: 'Ремонт дорожного покрытия',
                neededResources: [
                    { viewName: 'информация от системы мониторинга ТС', amount: '' }
                ],
                img: 'issue-img.png',
                sources: [
                    'Данные от 10 автомобилей, проехавших за 16 минут', 'информация от системы мониторинга ТС'
                ]
            },
            {
                id: 3,
                title: 'Обновление разметки на Фермском шоссе',
                date: '12.04.19',
                responsible: 'Петров В.С.',
                brigada: '№1',
                prioritet: 90,
                status: 'planned',
                cords: { x: 405, y: 700 },
                reason: 'Проблема с дорожной разметкой',
                recommendation: 'Обновление разметки',
                neededResources: [
                    { viewName: 'информация от системы мониторинга ТС', amount: '' }
                ],
                img: 'issue-img.png',
                sources: [
                    'Данные от 10 автомобилей, проехавших за 16 минут', 'информация от системы мониторинга ТС'
                ]
            },
            {
                id: 4,
                title: 'Обследование дорожного знака на Фермском шоссе',
                date: '12.04.19',
                responsible: 'Петров В.С.',
                brigada: '№1',
                prioritet: 80,
                status: 'planned',
                cords: { x: 370, y: 680 },
                reason: 'Проблема с видимостью дорожного знака',
                recommendation: 'Обследовать дорожный знак',
                neededResources: [
                    { viewName: 'информация от сенсоров', amount: '' }
                ],
                img: 'issue-img.png',
                sources: [
                    'Данные от 10 автомобилей, проехавших за 16 минут', 'информация от сенсоров'
                ]
            },
            {
                id: 5,
                title: 'Ремонт светофора на Фермском шоссе',
                date: '12.04.19',
                responsible: 'Петров В.С.',
                brigada: '№1',
                prioritet: 70,
                status: 'planned',
                cords: { x: 310, y: 544 },
                reason: 'Проблема с видимостью сигналов светофора',
                recommendation: 'Ремонт светофора',
                neededResources: [
                    { viewName: 'информация от сенсоров', amount: '' }
                ],
                img: 'issue-img.png',
                sources: [
                    'Данные от 10 автомобилей, проехавших за 16 минут', 'информация от сенсоров'
                ]
            },
            {
                id: 6,
                title: 'Наледь на дорожном полотне на Фермском шоссе',
                date: '12.04.19',
                responsible: 'Петров В.С.',
                brigada: '№1',
                prioritet: 60,
                status: 'planned',
                cords: { x: 289, y: 398 },
                reason: 'Наледь на дорожном полотне',
                recommendation: 'Очистка дорожного полотна',
                neededResources: [
                    { viewName: 'информация от систем ADAS', amount: '' },
                ],
                img: 'issue-img.png',
                sources: [
                    'Данные от 10 автомобилей, проехавших за 16 минут', 'информация от систем ADAS'
                ]
            },
            {
                id: 7,
                title: 'Препятствие на пути движения на Фермском шоссе',
                date: '12.04.19',
                responsible: 'Петров В.С.',
                brigada: '№1',
                prioritet: 55,
                status: 'planned',
                cords: { x: 237, y: 430 },
                reason: 'Препятствие на пути движения',
                recommendation: 'Убрать препятствие',
                neededResources: [
                    { viewName: 'информация от систем ADAS', amount: '' },
                ],
                img: 'issue-img.png',
                sources: [
                    'Данные от 10 автомобилей, проехавших за 16 минут', 'информация от систем ADAS'
                ]
            },
            {
                id: 8,
                title: 'Ремонт дорожного покрытия на Фермском шоссе',
                date: '12.04.19',
                responsible: 'Петров В.С.',
                brigada: '№1',
                prioritet: 40,
                status: 'planned',
                cords: { x: 60, y: 440 },
                reason: 'Повторные ДТП на этом участке дороги',
                recommendation: 'Ремонт дорожного покрытия, обновление разметки',
                neededResources: [
                    { viewName: 'информация из ГИБДД', amount: '' }
                ],
                img: 'issue-img.png',
                sources: [
                    'Данные от 10 автомобилей, проехавших за 16 минут', 'Информация из ГИБДД'
                ]
            },
            {
                id: 9,
                title: 'Ремонт дорожного покрытия на Фермском шоссе',
                date: '12.04.19',
                responsible: 'Петров В.С.',
                brigada: '№1',
                prioritet: 80,
                status: 'in progress',
                cords: { x: 190, y: 380 },
                reason: 'Проблема с дорожным покрытием',
                recommendation: 'Ремонт дорожного покрытия',
                neededResources: [
                    { viewName: 'информация от систем ADAS', amount: '' }
                ],
                img: 'issue-img.png',
                sources: [
                    'Данные от 10 автомобилей, проехавших за 16 минут', 'Данные от системы мониторинга КДМ'
                ]
            },
            {
                id: 10,
                title: 'Обследование дорожного знака на Фермском шоссе',
                date: '12.04.19',
                responsible: 'Петров В.С.',
                brigada: '№1',
                prioritet: 57,
                status: 'in progress',
                cords: { x: 320, y: 600 },
                reason: 'Проблема с видимостью дорожного знака',
                recommendation: 'Обследовать дорожный знак',
                neededResources: [
                    { viewName: 'информация от сенсоров', amount: '' }
                ],
                img: 'issue-img.png',
                sources: [
                    'Данные от 10 автомобилей, проехавших за 16 минут', 'Информация от сенсоров'
                ]
            },
            {
                id: 11,
                title: 'Очистка дорожного полотна на Фермском шоссе',
                date: '12.04.19',
                responsible: 'Петров В.С.',
                brigada: '№1',
                prioritet: 20,
                status: 'in progress',
                cords: { x: 350, y: 570 },
                reason: 'Наледь на дорожном полотне',
                recommendation: 'Очистка дорожного полотна',
                neededResources: [
                    { viewName: 'информация от систем ADAS', amount: '' },
                ],
                img: 'issue-img.png',
                sources: [
                    'Данные от 10 автомобилей, проехавших за 16 минут', 'Информация от систем ADAS'
                ]
            },
            {
                id: 12,
                title: 'Обновление разметки на Фермском шоссе',
                date: '12.04.19',
                responsible: 'Петров В.С.',
                brigada: '№1',
                prioritet: 10,
                status: 'finished',
                cords: { x: 270, y: 360 },
                reason: 'Проблема с дорожной разметкой',
                recommendation: 'Обновление разметки',
                neededResources: [
                    { viewName: 'информация от сенсоров', amount: '' }
                ],
                img: 'issue-img.png',
                sources: [
                    'Данные от 10 автомобилей, проехавших за 16 минут', 'Данные от системы мониторинга КДМ'
                ]
            },
            {
                id: 13,
                title: 'Убрать препятствие на пути движения на Фермском шоссе',
                date: '12.04.19',
                responsible: 'Петров В.С.',
                brigada: '№1',
                prioritet: 6,
                status: 'finished',
                cords: { x: 360, y: 400 },
                reason: 'Препятствие на пути движения',
                recommendation: 'Убрать препятствие',
                neededResources: [
                    { viewName: 'информация от систем ADAS', amount: '' }
                ],
                img: 'issue-img.png',
                sources: [
                    'Данные от 10 автомобилей, проехавших за 16 минут', 'Данные от системы мониторинга КДМ'
                ]
            }
        ];
        this.assigned = [];
    }
    IssueService.prototype.getIssues = function () {
        return this.issues;
    };
    /**
     * Все в статусе 'planned', которых нет в массиве assigned
     */
    IssueService.prototype.getUnassigned = function () {
        var _this = this;
        return this.issues.filter(function (issue) { return issue.status === 'planned' && _this.assigned.map(function (assigned) { return assigned.id; }).indexOf(issue.id) === -1; });
    };
    /**
     * Все в статусе КРОМЕ 'planned', которые есть в массиве assigned, с данными из массива assigned
     */
    IssueService.prototype.getAssigned = function () {
        var _this = this;
        return this.issues.filter(function (issue) { return issue.status !== 'planned' || _this.assigned.map(function (assigned) { return assigned.id; }).indexOf(issue.id) !== -1; })
            .map(function (issue) { return _this.assigned.find(function (assigned) { return assigned.id === issue.id; }) || issue; });
    };
    IssueService.prototype.assign = function (data) {
        if (this.assigned.map(function (issue) { return issue.id; }).indexOf(data.issue.id) === -1) {
            for (var prop in data.data) {
                if (data.data.hasOwnProperty(prop)) {
                    data.issue[prop] = data.data[prop];
                }
            }
            this.assigned.push(data.issue);
        }
    };
    IssueService.prototype.updateIssues = function (planned, inProgress, finished) {
        var _this = this;
        this.issues.forEach(function (issue, i) {
            if (planned.indexOf(issue.id) > -1) {
                _this.issues[i].status = 'planned';
            }
            if (inProgress.indexOf(issue.id) > -1) {
                _this.issues[i].status = 'in progress';
            }
            if (finished.indexOf(issue.id) > -1) {
                _this.issues[i].status = 'finished';
            }
        });
    };
    IssueService.ngInjectableDef = i0.defineInjectable({ factory: function IssueService_Factory() { return new IssueService(); }, token: IssueService, providedIn: "root" });
    return IssueService;
}());
export { IssueService };

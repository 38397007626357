/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./planning.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./planning-side/planning-side.component.ngfactory";
import * as i3 from "./planning-side/planning-side.component";
import * as i4 from "./map/map.component.ngfactory";
import * as i5 from "./map/map.component";
import * as i6 from "./planning.component";
import * as i7 from "../_services/issue.service";
var styles_PlanningComponent = [i0.styles];
var RenderType_PlanningComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PlanningComponent, data: {} });
export { RenderType_PlanningComponent as RenderType_PlanningComponent };
export function View_PlanningComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "page-planning"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-planning-side", [], null, [[null, "selectedIssueOut"], [null, "activeTabOut"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedIssueOut" === en)) {
        var pd_0 = (_co.selectIssue($event) !== false);
        ad = (pd_0 && ad);
    } if (("activeTabOut" === en)) {
        var pd_1 = (_co.toggleMapIssues($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_PlanningSideComponent_0, i2.RenderType_PlanningSideComponent)), i1.ɵdid(2, 114688, null, 0, i3.PlanningSideComponent, [], { issues: [0, "issues"], selectedIssue: [1, "selectedIssue"] }, { selectedIssueOut: "selectedIssueOut", activeTabOut: "activeTabOut" }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-map", [], null, [[null, "selectedIssueOut"], [null, "assignedIssueOut"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedIssueOut" === en)) {
        var pd_0 = (_co.selectIssue($event) !== false);
        ad = (pd_0 && ad);
    } if (("assignedIssueOut" === en)) {
        var pd_1 = (_co.assignIssue($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_MapComponent_0, i4.RenderType_MapComponent)), i1.ɵdid(4, 114688, null, 0, i5.MapComponent, [], { issuesAssigned: [0, "issuesAssigned"], issues: [1, "issues"], selectedIssue: [2, "selectedIssue"] }, { selectedIssueOut: "selectedIssueOut", assignedIssueOut: "assignedIssueOut" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.issues; var currVal_1 = _co.selectedIssue; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.issuesAssigned; var currVal_3 = _co.issues; var currVal_4 = _co.selectedIssue; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4); }, null); }
export function View_PlanningComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-planning", [], null, null, null, View_PlanningComponent_0, RenderType_PlanningComponent)), i1.ɵdid(1, 114688, null, 0, i6.PlanningComponent, [i7.IssueService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PlanningComponentNgFactory = i1.ɵccf("app-planning", i6.PlanningComponent, View_PlanningComponent_Host_0, {}, {}, []);
export { PlanningComponentNgFactory as PlanningComponentNgFactory };

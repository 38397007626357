<div class="page-planning">
  <app-planning-side
    [issues]="issues"
    [selectedIssue]="selectedIssue"
    (selectedIssueOut)="selectIssue($event)"
    (activeTabOut)="toggleMapIssues($event)"
  ></app-planning-side>
  <app-map
    [issuesAssigned]="issuesAssigned"
    [issues]="issues"
    [selectedIssue]="selectedIssue"
    (selectedIssueOut)="selectIssue($event)"
    (assignedIssueOut)="assignIssue($event)"
  ></app-map>
</div>



<div class="map">
  <div class="marker"
       *ngFor="let issue of issues"
       (click)="selectIssue(issue)"
       [ngStyle]="{
          'left': issue.cords.x+'px',
          'top': issue.cords.y+'px',
          'z-index': (selectedIssue === issue)?'100':'10'
       }">
    <span *ngIf="!(selectedIssue === issue)">
       <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="21" cy="21" r="21" fill="#01628A"/>
      <path d="M30.2021 18.1504C30.2021 24.1665 21.2358 34.9214 21.2358 34.9214C21.2358 34.9214 12.2695 24.1665 12.2695 18.1504C12.2695 13.0782 16.2839 8.96631 21.2358 8.96631C26.1878 8.96631 30.2021 13.0782 30.2021 18.1504Z" fill="white"/>
      <ellipse cx="21.2357" cy="16.9889" rx="3.77528" ry="3.77528" fill="#01628A"/>
    </svg>
    </span>
   <span *ngIf="selectedIssue === issue">
     <svg width="34" height="48" viewBox="0 0 34 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6154 48C16.6154 48 33.2308 28.1104 33.2308 16.9846C33.2308 7.60427 25.7918 0 16.6154 0C7.43896 0 0 7.60427 0 16.9846C0 28.1104 16.6154 48 16.6154 48ZM16.5528 21.7805C20.402 21.7805 23.5225 18.6601 23.5225 14.8108C23.5225 10.9615 20.402 7.84102 16.5528 7.84102C12.7035 7.84102 9.58301 10.9615 9.58301 14.8108C9.58301 18.6601 12.7035 21.7805 16.5528 21.7805Z" fill="white"/>
    </svg>
   </span>

    <div class="marker-popup" [hidden]="!(selectedIssue === issue)">
      <svg class="marker-popup-appendix" width="90" height="16" viewBox="0 0 90 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M90 0L46.5893 15.5212C45.723 15.8309 44.7765 15.8326 43.9092 15.5259L6.10352e-05 0L90 0Z" fill="#EFF3F5"/>
      </svg>
      <div class="marker-popup-content">
        <div class="info">
          <div class="info-title">
            <div class="info-content">
              {{issue.title}}
            </div>
          </div>
          <div class="recommendations">
            <div class="icon">
              <svg width="23" height="27" viewBox="0 0 23 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22 6.31915V26H1.00015L1 1H16.4737M22 6.31915H16.4737V1M22 6.31915L16.4737 1M17.5788 10.1064H5.42102M17.5788 15.4255H5.42102M17.5788 20.2128H5.42102" stroke="black"/>
              </svg>
            </div>
            <div class="info-content">
              <div>Рекомендация</div>
              <p>{{issue.recommendation}}</p>
            </div>
          </div>

          <div class="sources">

            <div class="icon">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.6832 20.5538L12.0003 20.7717L12.3174 20.5538C12.6439 20.3299 20.3011 14.9891 20.3011 8.15217C20.3011 3.65707 16.5774 0 12.0003 0C7.42318 0 3.69942 3.65707 3.69942 8.15217C3.69997 14.9891 11.3572 20.3299 11.6832 20.5538ZM12.0008 1.08696C15.9681 1.08696 19.1949 4.25652 19.1949 8.15217C19.1949 13.6707 13.4308 18.3543 12.0008 19.4315C10.5714 18.3549 4.80675 13.6707 4.80675 8.15217C4.80675 4.25652 8.03413 1.08696 12.0008 1.08696ZM20.0765 17.9348H17.5342L16.4274 19.0217H19.419L22.1356 23.913H1.86493L4.58152 19.0217H7.57315L6.46637 17.9348H3.92409L0 25H24L20.0765 17.9348ZM15.3212 8.15217C15.3212 6.3538 13.832 4.8913 12.0008 4.8913C10.1697 4.8913 8.68049 6.3538 8.68049 8.15217C8.68049 9.95054 10.1697 11.413 12.0008 11.413C13.832 11.413 15.3212 9.95054 15.3212 8.15217ZM9.78727 8.15217C9.78727 6.95326 10.7801 5.97826 12.0008 5.97826C13.2216 5.97826 14.2144 6.95326 14.2144 8.15217C14.2144 9.35109 13.2216 10.3261 12.0008 10.3261C10.7801 10.3261 9.78727 9.35109 9.78727 8.15217Z" fill="black"/>
              </svg>
            </div>

            <div class="info-content">
              <div>Источники информации</div>
              <p *ngFor="let source of issue.sources">{{source}}</p>
            </div>

          </div>

        </div>
        <div class="img-action">
          <img src="/assets/{{issue.img}}" />
          <span>30.291782, 60.098292</span>
        </div>
        <!--<h6>Причина</h6>
        <div class="description-block">
          <div class="description">{{issue.reason}}</div>
          <div class="icon">
            <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="21.5" cy="21" r="21" fill="#FF8946"/>
              <circle cx="21.5" cy="21" r="17.0769" fill="white" stroke="#01628A" stroke-width="3"/>
              <path d="M12.6155 27.0577L25.9424 29.077H23.9232H12.6155V27.0577Z" fill="#01628A"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M16.1854 19.7369C16.2585 20.0266 16.5578 20.4087 16.5578 20.4087L17.0752 24.6627C17.2085 25.477 18.0423 26.1135 18.4484 26.2223L26.0387 28.2562C26.4447 28.365 27.5007 28.2348 28.0077 27.5921L30.5827 24.1667C30.5827 24.1667 31.0331 23.9854 31.2411 23.7711C31.4492 23.5567 31.6494 23.372 32.1062 21.6671C32.5511 20.0067 32.3508 19.3174 31.9808 19.0117L31.5514 18.8649L31.9685 17.3082C32.1036 16.8042 31.8667 16.4388 31.3513 16.3007L30.8984 16.1794C30.383 16.0413 30.042 16.2518 29.9069 16.7558L29.4898 18.3125L20.447 15.8895L20.8641 14.3328C20.9992 13.8288 20.7935 13.4718 20.2938 13.3379L19.8408 13.2165C19.3255 13.0784 18.9376 13.2764 18.8026 13.7804L18.3855 15.3371L17.9402 15.2496C17.4473 15.3399 16.9448 15.8409 16.5039 17.4865C16.0314 19.1872 16.128 19.4514 16.1854 19.7369ZM29.3065 20.0589C29.8531 20.2053 30.1908 20.7566 30.0518 21.2755C29.9127 21.7944 29.3446 22.1029 28.798 21.9565C28.2514 21.81 27.9136 21.2587 28.0527 20.7398C28.1917 20.221 28.7442 19.9082 29.3065 20.0589ZM19.6858 17.481C20.2324 17.6275 20.5701 18.1788 20.4311 18.6977C20.2921 19.2165 19.724 19.5251 19.1773 19.3786C18.6307 19.2322 18.293 18.6809 18.432 18.162C18.571 17.6431 19.1392 17.3345 19.6858 17.481ZM18.5968 21.6701L28.1862 24.2395C28.1862 24.2395 28.3776 24.2749 28.2675 24.4361C28.1809 24.5718 27.1274 25.942 26.8757 26.3195C26.7735 26.451 26.3737 26.6934 25.956 26.5656L19.3496 24.7954C18.92 24.7121 18.699 24.2874 18.6762 24.1224C18.6469 23.6697 18.4197 21.9563 18.4126 21.7955C18.4134 21.605 18.5968 21.6701 18.5968 21.6701Z" fill="#01628A"/>
            </svg>
          </div>
        </div>
        <h6>Рекомендация:</h6>
        <div class="description-block">
          <div class="description">{{issue.recommendation}}</div>
          <div class="icon">
            <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="21.5" cy="21" r="21" fill="#FF8946"/>
              <circle cx="21.5" cy="21" r="17.0769" fill="white" stroke="#01628A" stroke-width="3"/>
              <path d="M21.5 14L28.5 26H14.5L21.5 14Z" fill="#01628A"/>
            </svg>

          </div>
        </div>
        <h6>Источник данных:</h6>
        <div class="description-block">
          <div class="description">
            <div *ngFor="let resource of issue.neededResources">
              - {{resource.amount}} {{resource.viewName}}
            </div>
          </div>
          <div class="icon">
            <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="21.5" cy="21" r="21" fill="#FF8946"/>
              <circle cx="21.5" cy="21" r="17.0769" fill="white" stroke="#01628A" stroke-width="3"/>
              <circle cx="23" cy="15.5" r="5" fill="#01628A" stroke="#01628A"/>
              <circle cx="12.5" cy="23" r="2.5" fill="#01628A" stroke="#01628A"/>
              <circle cx="25.5" cy="29" r="3.5" fill="#01628A" stroke="#01628A"/>
              <path d="M14.5 22L22.5 15L26 28.5" stroke="#01628A"/>
            </svg>

          </div>
        </div>
        <div class="assign-issue">
          <button (click)="assignIssue($event)">Назначить задачу</button>
        </div> -->
      </div>
      <div class="popup_bottom">
        <div class="crue" *ngIf="issuesAssigned">
          <div>
            <span>Ответственный:</span> {{issue.responsible}}<br />
            <span>Бригада:</span> {{issue.brigada}}
          </div>
          <div>
            <span>Дата</span><br /> {{issue.date}}
          </div>
        </div>
        <div class="assign-issue" *ngIf="!issuesAssigned">
          <button (click)="assignIssue($event)">Назначить задачу</button>
        </div>
      </div>

    </div>
  </div>
  <div class="map-overlay" [hidden]="!selectedIssue && !openAssignPopup" (click)="closeAll()"></div>
  <app-assign-popup [selectedIssue]="selectedIssue" [openAssignPopup]="openAssignPopup" [overlay]="overlay" (closePopupOut)="openAssignPopup=false; overlay=false" (assignIssueOut)="assignSelectedIssue($event)"></app-assign-popup>
</div>
